<template>
    <div>
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <div>
            <div class='serviceContent'>
                <div class='serviceContentTop clearfix'>
                    <div class='fl'>
                        <div class='info'>
                            <span class='position'>{{recruitDetail.position}}</span>
                            <i class='salary'>{{ recruitDetail.salary === "0-0" ? "面议" : recruitDetail.salary }}</i>
                        </div>
                        <p class='recordName' v-if='type===0'>{{ recruitDetail.recordName }}</p>
                        <div class='address'>
                            <span class='addressDetails'>{{ recruitDetail.addressDetails }}</span>
                            <span>{{ recruitDetail.createdDate && recruitDetail.createdDate.split(" ")[0] }} {{type===0 ? '发布': ''}}</span>
                        </div>
                    </div>
                    <div  class='fr' v-if='type===0'>
                        <button @click='onGoToQrCode'>简历投递</button>
                    </div>
                </div>
                <div class='serviceContentCenter clearfix flex'>
                    <div class=' userInfo flex flex_length'>
                        <img :src="require('@/assets/images/dataShare/sjhm@2x.png')" alt="">
                        <span class='name'>{{ recruitDetail.linkman }}</span>
                        <span class='recruiter'>{{type===0 ? '招聘者': '求职者'}}</span>
                    </div>
                    <div class=' phone flex'>
                        <img :src="require('@/assets/images/dataShare/sjhm@2x.png')" alt="">
                        <span @click='onGoToQrCode' v-if='recruitDetail.phone' class='contactPhone'>{{recruitDetail.phone.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")}}</span>
                        <img class='contact' :src="require('@/assets/images/dataShare/pl@2x.png')" alt="">
                        <span @click='onGoToQrCode'>在线联系</span>
                    </div>
                </div>
                <div>
                    <div class='jobDescriptionTitle'>{{type===0 ? '职业介绍': '个人描述'}}</div>
                    <div class='jobDescription'>{{type===0?recruitDetail.jobDescription:recruitDetail.individualDescription }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
            breadList: [{
                name: '南繁服务'
            }, {
                name: '用工服务',
                path: '/employmentService'
            }, {
                name: ''
            }],
            recruitDetail: '',
            type: 0
        };
    },
    mounted() {
        if (this.$route.query && this.$route.query.id) {
            this.type = Number(this.$route.query.type);
            const name = this.type === 0 ? '招聘' : '求职';
            this.$set(this.breadList[2], 'name', name);
            if (this.type === 0) {
                this.getRecruitDetail(this.$route.query.id);
            } else {
                this.getResumeDetail(this.$route.query.id);
            }
        }
    },
    methods: {
        // 获取求职详情
        async getResumeDetail(id) {
            const api = this.$fetchApi.getResumeDetail.api + id;
            const data = await this.$fetchData.fetchPost({}, api, "json");
            if (data.code === "200" && data.result) {
                this.recruitDetail = data.result;
            }
        },
        // 获取招聘详情
        async getRecruitDetail(id) {
            const api = this.$fetchApi.getRecruitDetail.api + id;
            const data = await this.$fetchData.fetchPost({}, api, "json");
            if (data.code === "200" && data.result) {
                this.recruitDetail = data.result;
            }
        },
        onGoToQrCode() {
            this.$router.push({
                path: '/downLoad'
            });
        }
    }
};
</script>
<style lang="less" scoped>
    .serviceContent{
        width:1180px;
        margin: 19px auto 190px;
        .serviceContentTop{ 
            background: #fafafa;
            padding:20px 41px 21px 20px;
            color:#333;
            margin-bottom:10px;
            button{
                width: 90px;
                height: 36px;
                background-color: #265ae1;
                border-radius: 2px;
                text-align: center;
                line-height: 36px;
                color:#fff;
                font-size: 16px;
                margin-top:32px;
            }
        }
        .position{
            font-size: 24px;
            font-weight: bold;
            padding-right: 20px;
            display: inline-block;
        }
        .salary{
            color: #ff5454;
            font-size: 24px;
            font-style: normal;
            
        }
        .info{
            padding-bottom:15px;
        }
        .recordName{
            font-size: 16px;
        }
        .address{
            color: #757575;
            font-size:14px;
            padding-top:28px;
        }
        .addressDetails{
            margin-right:20px;
        }
        .serviceContentCenter{
            color:#333;
            background: #fafafa;
            padding: 20px 30px 20px 20px; 
            line-height: 24px;
            font-size:16px;
            margin-bottom:32px;
            .userInfo img{
                width: 50px;
                height: 50px;
                border-radius: 100%;
                margin-right:16px;
                background: #ccc;
            }
            .phone img{
                width: 24px;
                height: 24px;
                margin-right:15px;
            }
            .name{
                font-size: 20px;
                color: #333;
                margin:0 16px;
            }
            .recruiter{
                color: #757575;
                font-size: 14px;
            }
            .contactPhone{
                padding-right:20px;
                border-right:1px solid #d9d9d9;
                height:16px;
                line-height: 16px;
            }
            .contact{
                padding-left:20px;
            }
        }
        .jobDescriptionTitle{
            font-size:18px;
            border-bottom:1px solid #d9d9d9;
            padding-bottom:18px;
            line-height: 18px;
            margin-bottom:19px;
        }
        .jobDescriptionTitle::before{
            border-left: 6px solid #265ae1;
            height:20px;
            content: '';
            margin-right:10px;
        }
        .jobDescription{
            color: #333333;
            font-size:16px;
            line-height: 30px;
        }
    }
</style>